import BaseService from './base/base.service';

const jwt = require('jsonwebtoken');

class AuthService extends BaseService{

    constructor(){
        super('user')
    }

    isAuthenticated() {
        const token = this.getToken();
        const decoded = jwt.decode(token)


        if (!decoded) {
            console.log('invalid token')
            return false;
        }

        // if (decoded.realm_access)
            // localStorage.setItem('roles', JSON.stringify(decoded.realm_access.roles))

        const currentTime = Date.now().valueOf() / 1000
        if (token) {
            if (currentTime > decoded.exp) {
                console.log('token expired...')
                // this.refreshToken()
                return false;
            } else {
                return true;
            }
        } else {
            console.log('token not received')
            return false;
        }
    }

    getEmail(){
        const token = this.getToken();
        const decoded = jwt.decode(token)


        if (!decoded) {
            console.log('invalid token')
            return '';
        }

        return decoded.email
    }

    getUserId(){
        return this.get('get/id')
    }

    getRoles(){
        const token = this.getToken();
        const decoded = jwt.decode(token)


        if (!decoded) {
            console.log('invalid token')
            return [];
        }

        return decoded.realm_access.roles

    }


    // async refreshToken(){
    //   try{
    //     const response = await fetch(`${this.BASE_URL}/user/refresh`, {
    //       "method": "POST",
    //       "headers": {
    //         "Content-Type": "application/json"
    //       },
    //       "body": JSON.stringify(
    //         {
    //           refresh_token: localStorage.getItem('refresh_token')
    //         }
    //       )
    //     })

    //   const data = await response.json()

    //   localStorage.setItem('access_token', data.access_token);
    //   localStorage.setItem('refresh_token', data.refresh_token);

    //   return true;
    // }catch(e){
    //   return false;
    // }
    // }

    getToken() {
        return localStorage.getItem('access_token');
    }

    getName() {
        const token = this.getToken();
        const decoded = jwt.decode(token)


        if (!decoded) {
            console.log('invalid token')
            return false;
        }

        return decoded.name
    }

    
    logout() {
        window.location.href = '/#/login'
        localStorage.removeItem('access_token');
        localStorage.removeItem('refresh_token');
        sessionStorage.removeItem('next_url')
    }
}

export default AuthService;