import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import MembersAreaService from './services/MemberArea.service';

// const link = document.createElement('link');
// link.rel = 'stylesheet';
// // link.type = 'text/css';
// link.href =  process.env.NODE_ENV === 'development' ? `http://localhost:3001/members/school/style/${window.location.hostname}` : `//${window.location.host}/theme.ecss`

// link.onload = () => {
//   console.log('CSS carregado com sucesso');
// }

// link.onerror = () => {
//   console.error('Erro ao carregar o CSS');
// }

// document.head.appendChild(link);


window.dataLayer = window.dataLayer || [];
function gtag(){window.dataLayer.push(arguments);}
window.gtag = gtag;
gtag('js', new Date());


new MembersAreaService().get(`school/metadata/${window.location.hostname}`).then((response) => {
  if(response){
    window.localStorage.setItem('metadata', JSON.stringify({...response, school_id: response?._id}))

    if(response.name){
      document.title = `${response.name} - Área de membros`
    }

    ReactDOM.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
      document.getElementById('root')
    );
  }
})

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();



