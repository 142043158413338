import React from "react";
import { Route, Redirect } from "react-router-dom";
import AuthService from "../services/Authentication.service";

const PrivateRoute = (props) => {
    const authenticated = new AuthService().isAuthenticated()

    if(!authenticated)
        sessionStorage.setItem('next_url', window.location.href)
        
    return <Route
                path={props.path}
                exact={props.exact}
                component={() => authenticated ?  props.children : <Redirect to={{pathname: '/login'}} />}
            />
}

export default PrivateRoute;