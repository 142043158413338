import { formatInTimeZone } from 'date-fns-tz';

import format from 'date-fns/format';

import ptBR from 'date-fns/locale/pt-BR';

export function formatDate(data, formatStr) {
    return format(data, formatStr, { locale: ptBR });
}

export default function parseDateTime(date, format='dd/MM/yyyy HH:mm') { 
    return formatInTimeZone(new Date(date), 'America/Sao_Paulo', format);
}

