import React, { Suspense, lazy, useEffect } from 'react';

import {
  HashRouter,
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

import { IntlProvider } from 'rsuite';
import ptBR from 'rsuite/lib/IntlProvider/locales/pt_BR';

import { formatDate } from './utils/parseDateTime'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

import PrivateRoute from "./components/PrivateRoute.component"

import loadable from '@loadable/component'

import DefaultTemplate from './templates/Default.template';
import BlankTemplate from './templates/Reels.template';
import ReelsPage from './pages/Reels/reels.page';
import CoursePage from './pages/Course/Course.page';
import RichTextClassPage from './pages/ClassRoom/RichTextClass.page';

// import * as Sentry from "@sentry/react";

const HomePage = loadable(() => import( './pages/Home.page'));
const LoginPage = loadable(() => import( './pages/Login/Login.page'));
const RegisterPage = loadable(() => import( './pages/Register/Register.page'));
const AuthenticatedTemplate = loadable(() => import( './templates/Authenticated.template'));
const PasswordRecoveryPage = loadable(() => import( "./pages/PasswordRecovery/PasswordRecovery.page"));
const NotFoundPage = loadable(() => import( "./pages/NotFound/NotFound.page"));
const MyAccountPage = loadable(() => import( "./pages/MyAccount/MyAccount.page"));
const MyOrdersPage = loadable(() => import( "./pages/MyOrders/MyOrders.page"));
const ClassRoomPage = loadable(() => import( "./pages/ClassRoom/ClassRoom.page"));

// import CheckoutPage from "./pages/Checkout/Checkout.page"
// const AdminPage = loadable(() => import( './pages/Admin/Admin.page'));
// const ProductsPage = loadable(() => import( "./pages/Product/Product.page"));
// const AddProductPage = loadable(() => import( "./pages/Product/AddProduct.page"));
// const IntegrationsPage = loadable(() => import( './pages/Integrations/Integrations.page'));
// const WebhooksPage = loadable(() => import( "./pages/Integrations/subpages/Webhooks.page"));
// const OrderPage = loadable(() => import( './pages/Order/Order.page'));
// const SubscriptionPage = loadable(() => import( './pages/Subscription/Subscription.page'));
// const FinancePàge = loadable(() => import( './pages/Finance/Finance.page'));
// const ReportsPage = loadable(() => import( "./pages/Reports/Reports.page"));
// const AdminCategoriesPage = loadable(() => import( "./pages/Admin/Categories.page"));
// const AffiliatePage = loadable(() => import( "./pages/Affiliates/Affiliates.page"));
// const AffiliateMarketplacePage = loadable(() => import( "./pages/Affiliates/AffiliateMarketplace.page"));

library.add(fab, fas)

// Sentry.init({
//   dsn: "https://a799628161e7f9424b075afd02e93796@o662668.ingest.us.sentry.io/4507731393576960",
//   integrations: [
//     Sentry.browserTracingIntegration(),
//     Sentry.replayIntegration(),
//   ],
//   // Performance Monitoring
//   tracesSampleRate: 1.0, //  Capture 100% of the transactions
//   // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//   tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
//   // Session Replay
//   replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//   replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });


export default function App() {

  useEffect(() => {
    try{
      const link = document.createElement('link');
      link.rel = 'stylesheet';
      link.href =  process.env.NODE_ENV === 'development' ? `http://localhost:3001/members/school/style/${window.location.hostname}` : `//${window.location.host}/theme.ecss`
  
      document.head.appendChild(link);
    }catch(ex){
      console.error(ex)
    }
  }, [])

  return (
    <HashRouter basename="/">
      <div>
        <IntlProvider locale={ptBR}>
          <Switch>
            <Route exact={true} path="/register">
              <DefaultTemplate>
                <RegisterPage />
              </DefaultTemplate>
            </Route>
            <Route exact={true} path="/404">
              <DefaultTemplate>
                <NotFoundPage />
              </DefaultTemplate>
            </Route>
            <Route exact={true} path="/login">
              <DefaultTemplate>
                <LoginPage />
              </DefaultTemplate>
            </Route>
            <Route path="/forgot/:recoveryToken">
              <DefaultTemplate>
                <PasswordRecoveryPage />
              </DefaultTemplate>
            </Route>
            <Route exact={true} path="/forgot">
              <DefaultTemplate>
                <PasswordRecoveryPage />
              </DefaultTemplate>
            </Route>
            <PrivateRoute exact={true} path="/dashboard" key={window.location.pathname}>
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/account">
              <AuthenticatedTemplate>
                <MyAccountPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute exact={true} path="/myorders">
              <AuthenticatedTemplate>
                <MyOrdersPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <Route path="/active/:activationToken">
              <LoginPage activation="true" />
            </Route>
            <PrivateRoute path="/" exact={true} key={window.location.pathname}>
              <AuthenticatedTemplate>
                <HomePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/reels" exact={true} key={window.location.pathname}>
              <BlankTemplate>
                <ReelsPage />
              </BlankTemplate>
            </PrivateRoute>
            <PrivateRoute path="/reels/:reel_id" exact={true} key={window.location.pathname}>
              <BlankTemplate>
                <ReelsPage />
              </BlankTemplate>
            </PrivateRoute>
            <PrivateRoute path="/course/:courseId" exact={true} key={window.location.pathname}>
              <AuthenticatedTemplate>
                <CoursePage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/classroom/page/:moduleId" exact={true} key={window.location.pathname}>
              <AuthenticatedTemplate>
                <RichTextClassPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/classroom/:courseId/:moduleId" exact={true} key={window.location.pathname}>
              <AuthenticatedTemplate>
                <ClassRoomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <PrivateRoute path="/classroom/:courseId/:moduleId/:lessonId" exact={true} key={window.location.pathname}>
              <AuthenticatedTemplate>
                <ClassRoomPage />
              </AuthenticatedTemplate>
            </PrivateRoute>
            <Route path="*"  >
              <DefaultTemplate>
                <NotFoundPage />
              </DefaultTemplate>
            </Route>
          </Switch>
        </IntlProvider>
      </div>
    </HashRouter>
  );
}
